import Vue from "vue";
import VueObserveVisibility from "vue-observe-visibility";
import App from "@/app/Index.vue";
import http from "@/utils/http";
import VueClipboard from 'vue-clipboard2'
import router from "./router";
import store from "./store";
import "./includes";
// import { parseJwt } from "./utils/helpers";
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.config.productionTip = false;

const getLocal = localStorage.getItem("authToken");

if (getLocal) {
    http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("authToken")}`;
}

Vue.use(VueObserveVisibility);

const app = new Vue({
    router: router.router,
    store,
    render: (h) => h(App),
}).$mount("#app");

export default app;
