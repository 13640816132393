import Vue from "vue";
import VueTour from "vue-tour";
// eslint-disable-next-line import/no-extraneous-dependencies
import upperFirst from "lodash/upperFirst";
// eslint-disable-next-line import/no-extraneous-dependencies
import camelCase from "lodash/camelCase";
import "@/styles/main.scss";
// plugins apps
import "@/plugins/bootstrap-vue";
import Vuelidate from "vuelidate";
import http from "./utils/http";

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);

Vue.prototype.$http = http;
Vue.use(Vuelidate);

// global components import
const requireComponent = require.context(
    "./shared-components/",
    true,
    /\.(js|vue)$/i,
);

requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
    const componentName = upperFirst(
        camelCase(
            fileName
                ?.split("/")
                ?.pop()
                ?.replace(/\.\w+$/, ""),
        ),
    );
    Vue.component(componentName, componentConfig.default || componentConfig);
});
